import React from "react"
import Item from "./Item"
import PickUp from "./PickUp"
import CartFooter from "./CartFooter"
import { dateString } from "../utils"

export const Cart = ({ cartItems }) => {
  const [error, setError] = React.useState(null)
  const [items, setItems] = React.useState([])
  const [totalPrice, setTotalPrice] = React.useState(0)
  const [totalItems, setTotalItems] = React.useState(0)
  const maxHours = 18

  // Get today and tomorrow's date
  const today = new Date()
  // const timestamp = Date.parse('2022-09-27T18:44:00.000+08:00')
  // const today = new Date(timestamp)
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)  

  const [pickUpTime, setPickUpTime] = React.useState('ASAP')
  const [pickUpDay, setPickUpDay] = React.useState((today.getHours() > maxHours) ? dateString(tomorrow) : dateString(today))

  const increment = (id) => {
    if (totalItems > 4) {
      alert('You may only order 5 items.')
      return 
    }
    let tempItems = items    
    const idExists = (item) => item.id === id
    const itemIndex = tempItems.findIndex(idExists)
    tempItems[itemIndex].count = tempItems[itemIndex].count + 1
    setItems(tempItems)
    calculateCart()
    setError(error + 1)
  }
  const decrement = (id) => {
    let tempItems = items    
    const idExists = (item) => item.id === id
    const itemIndex = tempItems.findIndex(idExists)
    if (tempItems[itemIndex].count === 0) {
      return
    }
    tempItems[itemIndex].count = tempItems[itemIndex].count - 1
    setItems(tempItems)
    calculateCart()
    setError(error + 1)
  }
  const calculateCart = () => {
    var tempItemCount = 0
    var tempTotalPrice = 0
    const tempItems = items
    tempItems.forEach((item) => {
      tempItemCount = tempItemCount + item.count
      setTotalItems(tempItemCount)
      tempTotalPrice = tempTotalPrice + (item.count * item.price)
    })
    setTotalPrice(tempTotalPrice)
  }
  
  const onTimeChange = (event) => {
    setPickUpTime(event.target.value)
  }
  const onDayChange = (event) => {
    setPickUpDay(event.target.value)
  }
  
  const assembledItems = () => {
    let itemsForOrder = []
    items.forEach((item) => {
      if (item.count > 0) {
        itemsForOrder.push(item)
      }
    })
    return itemsForOrder
  }
  const assembledOrder = () => {
    return {
      items: assembledItems(),
      pickup: pickUpDay + ' ' + pickUpTime
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(assembledOrder())
    if (totalItems === 0) {
      alert('Please add at least 1 coffee')
    } else {
      alert('Your order has been submitted')
    }
    return
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(assembledOrder())
    }
    fetch('api-url', requestOptions)
      .then(res => res.json())
      .then(
        (result) => {

        },
        (error) => {

        }
      )
  }

  React.useEffect(() => {
    // load item data from API somewhere here
    let tempItems = [
      {
        id: 1,
        name: 'Americano',
        description: 'Rich espresso with hot water',
        price: 75,
        count: 0,
      },
      {
        id: 2,
        name: 'Latte',
        description: 'Rich espresso with hot steamed milk',
        price: 85,
        count: 0,
      },
      {
        id: 3,
        name: 'Black Coffee',
        description: 'Bitter and bold',
        price: 65,
        count: 0,
      },      
    ]
    setItems(tempItems)
  }, [])


  return (
    <div className="cart-container">
      <div className="greeting">
        What would you like to order?
      </div>
      <div className="products">
        { items.map(item => (
          <Item 
            key={ item.id } 
            id={ item.id } 
            name={ item.name } 
            price={ item.price } 
            description={ item.description } 
            count={ item.count }
            increment={ increment }
            decrement={ decrement }
            totalItems={ totalItems }
          ></Item>
        ))}
      </div>
      <PickUp 
        today={ today } 
        tomorrow={ tomorrow } 
        pickUpDay = { pickUpDay } 
        pickUpTime={ pickUpTime } 
        onTimeChange={ onTimeChange } 
        onDayChange={ onDayChange }
        maxHours={ maxHours }
        minHours={6}
      />
      <input className="button" type="submit" value="Order Now" onClick={onSubmit}/>

      <CartFooter totalItems={totalItems} totalPrice={totalPrice}/>
    </div>
  )
}
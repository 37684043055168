const CartFooter = ({ totalItems, totalPrice }) => {

  return (
    <div className="cart-footer">
      <div className="item-count">
        {totalItems} item(s)
      </div>
      <div className="cart-total">
        ₱ {totalPrice}.00
      </div>
    </div>
  )
}
export default CartFooter
import React from 'react'
import { twoDigit, dateString } from '../utils'

// TODO: reorganize code

const PickUp = ({ minHours, maxHours, today, tomorrow, pickUpDay, onTimeChange, onDayChange }) => {
  // Set max hours and minute increments
  // const maxHours = 18
  const minuteIncrements = 15
  
  const normalizeTime = (hours, mins) => {
    let period = 'AM'
    if (hours > 12) {
      hours -= 12
      period = 'PM'
    }
    if (hours === 12) {
      period = 'PM'
    }
    if (hours === 0) {
      hours = 12
    }
    return hours + ':' + twoDigit(mins) + ' ' + period
  }
  
  const generateTimes = (generateToday = false) => {

    let times = []
    let startHour = minHours
    let minMins = 0
    const maxMins = 60

    const todayHour = today.getHours()
    const todayMins = today.getMinutes()

    if (generateToday) {
      if (startHour < todayHour) {
        startHour = todayHour
      }
    }

    if (generateToday && (today.getHours() < maxHours) ) {
      times.push({
        value: "ASAP",
        label: "ASAP",
      })
    }
    for (let hour = startHour; hour < maxHours; hour++) {
      let startMins = minMins
      if (hour === todayHour) {
        if (todayMins < 15) {
          startMins = 30
        } else if (todayMins < 30) {
          startMins = 45
        } else if (todayMins < 45) {
          startMins = 0
          hour++
        }
      }
      for (let min = startMins; min < maxMins; min += minuteIncrements) {
        times.push( {
          value: twoDigit(hour) + ':' + twoDigit (min),
          label: normalizeTime(hour, min)
        }
          
        )
      }
    }
    return times
  }

  const [availableTimes, setAvailableTimes] = React.useState(generateTimes(true))

  const handleDayChange = (evt) => {
    console.log(evt.target.value)
    if (evt.target.value === dateString(tomorrow)) {
      setAvailableTimes(generateTimes())      
    } else {
      setAvailableTimes(generateTimes(true))
    }
    onDayChange(evt)
  }

  React.useEffect( ()=> {
    
  }, [])


  return (
    <div className="pickup-schedule">
      <div className="greeting">
        When will you pick it up?
      </div>
      
      <div className="pickup-day">
        <div>
          Day
        </div>
        <div className="select-wrapper">
          <select name="pickup-day" onChange={ handleDayChange } value={ pickUpDay }>
            <option value={ dateString(today) }>Today</option>
            <option value={ dateString(tomorrow)}>Tomorrow</option>
          </select>
        </div>
      </div>
      <div className="pickup-time">
        <div>
          Time
        </div>
        <div className="select-wrapper">
          <select name="pickup-time" onChange={ onTimeChange }>
            { availableTimes.map( time => (<option value={ time.value } key={ time.value }>{ time.label }</option>))}
          </select>
        </div>
      </div>      
    </div>
  )
}

export default PickUp
import React from 'react'

const Item = ({ id, name, description, price, count, increment, decrement, totalItems}) => {
  
  const handleIncrement = () => {
    if (totalItems > 5) {
      alert('You may only order 5 items')
    } else {
      increment(id)
    }
  }
  const handleDecrement = () => {
    decrement(id)
  }

  const handleCountChange = (e) => {
    console.log(e)
  }

  return (
    <div className="product">
      <div className="product-name">
        <div className="">
          {name}
        </div>
        <div className="description">
          {description}
        </div>
      </div>
      <div>
          
      </div>
      <div className="product-quantity">
        ₱ { price }
        <div className="button button-decrement" onClick={handleDecrement} disabled={ count === 0 }>-</div>
        <div className="product-quantity-count">
          <input value={count} name={'product[' + name + ']'} onChange={handleCountChange}/>
        </div>
        <div className="button button-increment" onClick={handleIncrement} disabled={ totalItems > 4}>+</div>
      </div>      
    </div>
  );
}

export default Item
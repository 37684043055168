import logo from './images/UPWhite.png'
import './App.css'
import React from 'react';
import { Cart } from './components/Cart';

const App = () => {
  return (
    <div className="App">      
      <div className="top-nav">
        <div className="logo">
          <img src={logo} alt="PICKUP COFFEE"/>
        </div>
        <div>

        </div>
      </div>
      <div className="order-form">
        <form>
          <Cart/>
        </form>
      </div>

    </div>
  );
}

export default App;

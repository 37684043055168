

export const twoDigit = (num) => {
  if (num < 10) {
    return '0' + num
  }
  return num
}

export const dateString = (date) => {
  const month = twoDigit(date.getMonth() + 1)
  const day = twoDigit(date.getDate())
  return date.getFullYear() + '-' + month + '-' + day
}

export const removeItem = (array, value) => {
  let index = array.indexOf(value)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

// TODO: date normalization
